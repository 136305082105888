import React from 'react';
import SelectField from "../../Forms/v2/SelectField";

const CheckBoxNAResponse = ({form, filled_form, currentId, handleSetFilledForm}) => {
    return (
        <>
            <SelectField name={form[currentId]?.name} id={form[currentId]?.field || form[currentId]?.id} label={form[currentId]?.label} value={filled_form[String(form[currentId].field)]||String(form[currentId]?.value)}
                         onchange={({value}: any) => handleSetFilledForm(value, currentId)}>
                <>
                    <option>Sim</option>
                    <option>Não</option>
                    <option>N/A</option>
                </>
            </SelectField>
        </>
    );
};

export default CheckBoxNAResponse;
