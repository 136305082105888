import React from 'react';
import SelectField from "../../Forms/v2/SelectField";
import TextResponse from "./Text";

const SelectDoubleText = ({ form, filled_form, currentId, mask, handle }) => {
    return (
        <div className={'flex flex-col gap-2 border border-gray-400 rounded-lg p-4'}>
            {
                !!form[currentId]?.children[0]?.visibility ?
                    <SelectField name={form[currentId]?.children[0]?.name} id={form[currentId]?.children[0]?.field || form[currentId]?.children[0]?.id} label={form[currentId]?.children[0]?.label}
                        value={filled_form[String(form[currentId]?.children[0]?.field)] || String(form[currentId]?.children[0]?.value)}
                        onchange={({ value }: any) => handle(value, currentId, 0)}>
                        <>
                            {form[currentId]?.children[0]?.accept_values ? JSON.parse(String(form[currentId]?.children[0]?.accept_values))?.map((row: any, id: any) => (
                                <option key={id} value={row}>{row}</option>
                            )) : null}
                        </>
                    </SelectField>
                    : ''
            }

            {
                !!form[currentId]?.children[1]?.visibility ?
                    <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={1} />
                    : ''
            }

            {
                !!form[currentId]?.children[2]?.visibility ?
                    <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} index={2} />
                    : ''
            }
        </div>
    );
};

export default SelectDoubleText;
