import { HiOutlineOfficeBuilding, HiOutlineBriefcase, HiOutlineClipboardCheck, HiOutlineClipboardList, HiOutlinePencilAlt, HiOutlineTrash, HiOutlineCheckCircle, HiOutlineBan, HiOutlineDownload, HiDocumentAdd, HiOutlineDocumentAdd, HiMap, HiOutlineCalendar, HiCheck } from "react-icons/hi";
import Card from "../../components/Cards/Card";
import HeaderButton from "../../components/HeaderButton";
import SearchField from "../../components/SearchField";
import 'react-loading-skeleton/dist/skeleton.css'
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import CardSkeletonComponent from "../../components/Skelekton/CardSkelekton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import IMask from "imask";
import axiosClient from "../../services/axios";
import ModalComponent from "../../components/Modal";
import { useStateContext } from "../../contexts/StateContextProvider";
import { toast } from "react-toastify";
import LoadingIndex from "../../components/LoadingIndex";
import useDebounce from "../../hooks/useDebounce";
import ScrollYBoxFormsComponent from "../../components/ScrollYBoxForms";

export function GenerateMask(classe: string, mask: any) {
    let input = document.querySelectorAll<HTMLElement>(`.${classe}`),
        styleMask: any = {
            mask,
            overwrite: true
        };
    if (classe === 'phone') {
        styleMask = {
            ...styleMask,
            lazy: true,
            placeholderChar: " ",
            placeholder: {
                show: 'always'
            }
        }
    }
    // @ts-ignore
    for (const el of input) {
        IMask(el, styleMask);
    }
}

interface ICards {
    id: string,
    title: string,
    status: string
}

const Home = () => {
    const { currentUser } = useStateContext();
    const [load, setLoad] = useState(true);
    const [optionsLoad, setOptionsLoad] = useState(true);
    const [cards, setCards] = useState<ICards[] | any[]>([]);
    const [toNext, setToNext] = useState<any>(null);
    const [toDown, setToDown] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [trackModal, setTrackModal] = useState(false);
    const [currentId, setCurrentId] = useState<string | null>(null);
    const [currentStatus, setCurrentStatus] = useState<string | null>(null);
    const [tracking, setTracking] = useState<any[]>([]);
    const fetchMore = useRef<HTMLDivElement>(null);
    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
    const fetchList = async () => {
        await sleep(1000);
        if (toNext) {
            let spliceInt = -1;
            if (toNext.includes("search")) {
                spliceInt = -2;
            }
            let base = '/' + toNext?.split('/').splice(spliceInt).join('/');
            if (base.includes("/list") || base.includes("/search")) {
                base = base.replace("/home", "");
                const axios = await axiosClient.get(`/home${base}`).catch(() => toast.error("Nenhum resultado encontrado"));

                const scrollYBox = document.getElementById("scrollYBox");
                scrollYBox?.scroll(0, scrollYBox.clientHeight);
                return axios;
            }
        }
        return await axiosClient.get(`/home/list?page=${currentId}`).catch(() => toast.error("Nenhum resultado encontrado"));
    }

    const fetchSearch = async (query: string) => {

        query = query.trim().replace(/\.|\/|-/g, "");
        return await axiosClient.get(`/home/search/${query}`).catch(() => toast.error("Nenhum resultado encontrado"));
    }
    const fetchData = async () => {
        setLoad(false);
        const fetch: any = await fetchList();
        if (!fetch?.data) {
            fetch.data = { data: [], meta: { total: 0 }, links: { next: null } }
        }
        const { data: { data, meta: { total }, links: { next } } }: any = fetch;
        setToNext(next);
        if (data && total) {
            const haystick = new Set(cards.map((dt: any) => dt.id));
            const values = data.filter((dt: any) => !haystick.has(dt.id))
            setCards([
                ...cards, ...values
            ])
        }
        setLoad(true)
        if (firstLoad) {
            setToDown(true);
        }
        setFirstLoad(true);
    }

    useEffect(() => {
        setToDown(false);
    }, [firstLoad]);


    useEffect(() => {
        fetchDebounce();
    }, [currentUser]);


    useEffect(() => {
        let observer = new IntersectionObserver(async (entries) => {
            if (entries.some(entry => entry.isIntersecting)) {
                if (toNext) {
                    fetchDebounce();
                    setFirstLoad(false);
                }
            }
        });
        if (fetchMore.current) observer.observe(fetchMore.current);
        return () => observer.disconnect();
    }, [toNext]);

    const doSearch = async ({ target: { value } }: any) => {
        if (value.length > 3 || value.length == 0) {
            const fetch: any = await fetchSearch(value);
            if (!fetch?.data) {
                fetch.data = { data: [], meta: { total: 0 }, links: { next: null } }
            }
            const { data: { data, meta: { total }, links: { next, last } } }: any = fetch;
            setToNext(next || last);
            if (data) {
                setCards([
                    ...data
                ])
            }
        }
    }

    const debounce = useDebounce(doSearch, 500);
    const fetchDebounce = useDebounce(fetchData, 300);
    const openModal = (id: any, status: any) => {
        if (!!currentId && !!currentStatus) {
            document?.querySelector("html")?.scrollTo(0, 25);
        }
        setCurrentId(id);
        setCurrentStatus(status);
    }

    const closeModal = () => {
        setModal(false);
        setCurrentId(null);
        setCurrentStatus(null)
    }

    const closeTrackModal = () => {
        setTrackModal(false);
        setModal(false);
        setCurrentId(null);
        setCurrentStatus(null)
    }

    useEffect(() => {
        if (!!currentId) {
            setModal(true);
        }
    }, [currentId]);

    useEffect(() => {
        if (!cards || cards?.length === 0) {
            fetchDebounce();
            setOptionsLoad(true);
        }
    }, [cards]);

    const downloadDoc = (event: SyntheticEvent) => {
        event.preventDefault()
        setOptionsLoad(false);
        if (currentStatus && ['finalizado', 'rejeitado', 'aprovado'].includes(currentStatus?.toLocaleLowerCase())) {
            axiosClient.post('respostas/download-document', { id: currentId })
                .then(({ data: { url, title } }) => {
                    const link = document.createElement('a');
                    link.setAttribute('id', 'downloadFile')
                    link.setAttribute('target', '_blank')
                    link.setAttribute('href', url)
                    document.body.appendChild(link);
                    link.click();
                    document.getElementById('downloadFile')?.parentNode?.removeChild(link);
                    toast.success(`Download do documento: ${title}`)
                })
                .catch(() => null)
                .finally(() => {
                    setOptionsLoad(true);
                });
        } else {
            toast.error("Avaliação ainda não foi finalizada!");
        }
    }

    const approveDocument = (event: SyntheticEvent) => {
        event.preventDefault()
        setOptionsLoad(false);
        axiosClient.post('respostas/approve-document', { id: currentId })
            .then(({ data: { message } }) => {
                toast.success(message)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(() => null)
            .finally(async () => {
                setModal(false);
                setCards([]);
            });
    }

    const rejectDocument = (event: SyntheticEvent) => {
        event.preventDefault()
        setOptionsLoad(false);
        axiosClient.post('respostas/reject-document', { id: currentId })
            .then(({ data: { message } }) => {
                toast.success(message)
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch(() => {
            }).finally(async () => {
                setModal(false);
                setCards([]);
            });
    }

    const seeTrack = (event: SyntheticEvent) => {
        event.preventDefault()
        setOptionsLoad(false);
        axiosClient.post('respostas/survey-track', { id: currentId })
            .then(({ data: { data } }) => {
                setTracking(data);
            })
            .catch(() => {
            }).finally(async () => {
                setTrackModal(true);
                setModal(false);
                setOptionsLoad(true);
            });
    };

    return (
        <div>
            <header
                className={'max-w-sm sm:max-w-2xl flex justify-center gap-4'}>
                {currentUser?.role === 1 ?
                    (<div className={'w-full mb-4'}>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={16}
                            cubeEffect={{
                                shadow: false,
                                slideShadows: false,
                            }}
                            modules={[Scrollbar]}
                            className="mySwiper flex mb-4"
                        >
                            <SwiperSlide className={'flex'}>
                                <HeaderButton url={'empresas/listar'} title={"Empresas"}>
                                    <HiOutlineOfficeBuilding className={'text-6xl text-main-color'} />
                                </HeaderButton>
                            </SwiperSlide>
                            <SwiperSlide className={'flex'}>
                                <HeaderButton url={'vistoriadores/listar'} title={"Vistoriadores"}>
                                    <HiOutlineBriefcase className={'text-6xl text-main-color'} />
                                </HeaderButton>
                            </SwiperSlide>
                            <SwiperSlide className={'flex'}>
                                <HeaderButton url={'vistorias/listar'} title={"Vistorias"}>
                                    <HiOutlineClipboardCheck className={'text-6xl text-main-color'} />
                                </HeaderButton>
                            </SwiperSlide>
                            <SwiperSlide>
                                <HeaderButton url={'formularios/listar'} title={"Formulários"}>
                                    <HiOutlineClipboardList className={'text-6xl text-main-color'} />
                                </HeaderButton>
                            </SwiperSlide>
                            <SwiperSlide>
                                <HeaderButton url={'campos/listar'} title={"Campos"}>
                                    <HiOutlineDocumentAdd className={'text-6xl text-main-color'} />
                                </HeaderButton>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    ) : <div className={'w-full mb-4'}></div>}
            </header>
            <main className={'max-w-sm sm:max-w-2xl flex flex-col items-center justify-center gap-3 -mt-4'}>
                <SearchField placeholder={'Buscar empresas, vistoriador ou vistoria'} onChange={debounce} />
                <ScrollYBoxFormsComponent toDown={toDown}>
                    {
                        load ?
                            (cards && cards.length > 0 ? Array.from(new Set(cards)).map(({ id, title, company, surveyor, endereco, date, status }) =>
                                <Card key={id} title={title} status={status} info={[company, date, endereco, surveyor]} click={() => openModal(id, status)} />) :
                                ("Nenhum resultado.")) :
                            <CardSkeletonComponent />
                    }
                    {
                        toNext ?
                            <LoadingIndex fetchMore={fetchMore} /> :
                            <div className={'w-full text-center flex justify-center pb-5'}></div>
                    }

                </ScrollYBoxFormsComponent>
            </main>
            <ModalComponent title={'Opções'} show={modal} setShow={closeModal}>
                <ul className="flex flex-col gap-3">
                    {(currentStatus) ?
                        (
                            ((!['finalizado', 'aprovado'].includes(currentStatus?.toLocaleLowerCase())) && currentUser?.role === 3) ?
                                (
                                    <li>
                                        <a href={`/resposta/${currentId}`}
                                            className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                            <HiOutlinePencilAlt className={'text-2xl'} />
                                            {
                                                optionsLoad ?
                                                    <span className="flex-1 ml-3 whitespace-nowrap">Responder</span> :
                                                    <LoadingIndex classes={'w-full text-center flex justify-center'} fetchMore={fetchMore} />
                                            }
                                        </a>
                                    </li>) :
                                (<>
                                    {['finalizado'].includes(currentStatus?.toLocaleLowerCase()) && currentUser?.role === 1 ?
                                        (<>
                                            <li>
                                                <button type={"button"} onClick={approveDocument}
                                                    className="flex justify-start text-center w-full cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-green-600 hover:text-white group hover:shadow">
                                                    <HiOutlineCheckCircle className={'text-2xl text-green-600 group-hover:text-white'} />
                                                    {
                                                        optionsLoad ?
                                                            <span className="ml-3 whitespace-nowrap group-hover:text-white">Aprovar documento</span> :
                                                            <LoadingIndex classes={'w-full text-center flex justify-center'} fetchMore={fetchMore} />
                                                    }
                                                </button>
                                            </li>
                                            <li>
                                                <button type={"button"} onClick={rejectDocument}
                                                    className="flex justify-start text-center w-full cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-red-600 group hover:shadow">
                                                    <HiOutlineBan className={'text-2xl text-red-600 group-hover:text-white'} />
                                                    {
                                                        optionsLoad ?
                                                            <span className="ml-3 whitespace-nowrap group-hover:text-white">Reprovar documento</span> :
                                                            <LoadingIndex classes={'w-full text-center flex justify-center'} fetchMore={fetchMore} />
                                                    }
                                                </button>
                                            </li>
                                        </>) : <li></li>
                                    }
                                    {(!['recebido', 'em andamento'].includes(currentStatus?.toLocaleLowerCase())) && currentUser?.role === 1 ?
                                        <li>
                                            <button type={"button"} onClick={downloadDoc}
                                                className="flex justify-start text-center cursor-pointer w-full items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                                <HiOutlineDownload className={'text-2xl'} />
                                                {
                                                    optionsLoad ?
                                                        <span className="ml-3 whitespace-nowrap">Baixar documento</span> :
                                                        <LoadingIndex classes={'w-full text-center flex justify-center'} fetchMore={fetchMore} />
                                                }
                                            </button>
                                        </li>
                                        : (!['recebido', 'em andamento'].includes(currentStatus?.toLocaleLowerCase())) && currentUser?.role !== 1 ? <li>
                                            <div className="h-10 bg-emerald-500 cursor-not-allowed flex gap-2 items-center p-2 text-white rounded-lg text-md font-bold">
                                                <HiCheck className="text-2xl"/> <p>Documento recebido</p>
                                            </div>
                                        </li> : ''
                                    }

                                    {currentUser?.role === 1 &&
                                        <>
                                        <li>
                                            <button type={"button"} onClick={seeTrack}
                                                className="flex justify-start text-center cursor-pointer w-full items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                                <HiMap className={'text-2xl'} />
                                                {
                                                    optionsLoad ?
                                                        <span className="ml-3 whitespace-nowrap">Rastreio</span> :
                                                        <LoadingIndex classes={'w-full text-center flex justify-center'} fetchMore={fetchMore} />
                                                }
                                            </button>
                                        </li>
                                        {
                                            ['em andamento', 'rejeitado'].includes(currentStatus?.toLocaleLowerCase()) &&
                                            <li>
                                                <a href={`/resposta/${currentId}`}
                                                    className="flex cursor-pointer items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow">
                                                    <HiOutlinePencilAlt className={'text-2xl'} />
                                                    {
                                                        optionsLoad ?
                                                            <span className="flex-1 ml-3 whitespace-nowrap">Verificar respostas</span> :
                                                            <LoadingIndex classes={'w-full text-center flex justify-center'} fetchMore={fetchMore} />
                                                    }
                                                </a>
                                            </li>
                                        }
                                        </>
                                    }
                                </>)
                        )
                        : (<li></li>)
                    }
                </ul>
            </ModalComponent>
            <ModalComponent title={'Rastreio'} show={trackModal} setShow={closeTrackModal}>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    #
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Descrição
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Criado em
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {(!!tracking && tracking.length > 0) && tracking.map((track, index) => {
                                return (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            <HiOutlineCalendar />
                                        </th>
                                        <td className="px-6 py-4">
                                            {track?.description}
                                        </td>
                                        <td className="px-6 py-4">
                                            {track?.createdAt}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>

            </ModalComponent>
        </div>
    );
};

export default Home;
