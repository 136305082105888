import React, { FC, useEffect, useState } from 'react';
import FileInputComponent from "./v2/FileInput";
import TextResponse from "../Response/v2/Text";
import axiosClient from "../../services/axios";
import SelectResponse from "../Response/v2/Select";
import CheckBoxResponse from "../Response/v2/Checkbox";
import CheckBoxNAResponse from "../Response/v2/CheckBoxNA";
import TextFileResponse from "../Response/v2/TextFile";
import TripleTextResponse from "../Response/v2/TripleText";
import PresenteAusenteNA from "../Response/PresenteAusenteNA";
import SelectDoubleText from "../Response/v2/SelectDoubleText";
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import useDebounce from '../../hooks/useDebounce';
import uuid from 'react-uuid';


interface IProp {
    form: any,
    filled_form: any,
    currentId: any,
    handleSetFilledForm: any,
}

const url = process.env.REACT_APP_BASE_URL;

const hasUpload = [];

function uploadoFile({ stream, title, callback, fieldType, currentId, childIndex, clearStreamFunc, clearUrlFunc }: any) {
    const MySwal = withReactContent(Swal)
    if (
        stream &&
        title &&
        stream.size > 0
    ) {
        try {
            new Compressor(stream, {
                quality: 0.5,
                success(result: any) {
                    const isIos = /iPad|iPhone|iPod/.test(navigator.platform) ||
                        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
                    if (!isIos && !hasUpload.includes(result?.name)) {
                        try {
                            const url = window.URL.createObjectURL(new Blob([result]));
                            const link = document.createElement("a");
                            link.setAttribute('id', 'downloadLink');
                            link.href = url;
                            link.setAttribute(
                                "download",
                                new Date().getTime() + '_' + result?.name || "file"
                            );
                            document.body.append(link);
                            document.getElementById("downloadLink").click();
                            document.getElementById("downloadLink").remove();
                            hasUpload.push(result?.name);
                        } catch (e) {
                        }
                    }

                    MySwal.fire({
                        title: "Carregando imagem",
                        html: "Aguarde um momento...",
                        timer: 100000000,
                        timerProgressBar: true,
                        allowOutsideClick: false,
                        didOpen: () => {
                            Swal.showLoading();
                        }
                    })
                    const form = new FormData();
                    form.append("file", result);
                    form.append("title", title.substring(0, 40) + "_" + Math.floor(Date.now() / 1000));
                    axiosClient.post('/respostas/importar-imagem', form, {}).then((response) => {
                        if (!(response?.status >= 200 && response?.status < 300)) {
                            clearStreamFunc(null);
                            clearUrlFunc("");
                            toast.error("Não foi possível enviar a imagem!")
                        }
                        if (!fieldType) {
                            callback(response?.data?.data?.value, currentId);
                        } else {
                            callback(response?.data?.data?.value, currentId, childIndex);
                        }
                        return;
                    }).catch(() => {
                        clearStreamFunc(null);
                        clearUrlFunc("");
                        toast.error("Não foi possível enviar a imagem!")
                    }).finally(() => {
                        setTimeout(() => MySwal.close(), 500);
                    });
                },
                error(err) {
                    toast.error("Não foi possível enviar a imagem!")
                },
            });
        } catch (e) {
            toast.error("Não foi possível enviar a imagem!")
        }
    } else {
        if (stream && stream?.size === 0) return;
        toast.error("Preencha os campos abaixo!")
    }
}

const backend = process.env.REACT_APP_BASE_URL + '/storage';


const DynamicFullFormComponent: FC<IProp> = ({ form, filled_form, currentId, handleSetFilledForm }) => {
    const [idForFileInput, setIdForFileInput] = useState(String(uuid()));
    const [stream, setStream] = useState<File | null>(null);
    const [mask, setMask] = useState('');
    const [fileUrl, setFileUrl] = useState('');

    const specialHandleSetFilledForm = (value: any, index: number, childIndex?: any) => {
        if (value) {
            handleSetFilledForm(value, currentId, childIndex);
        }
    }

    const manageFile = async ({ index, uploadArgs: { func, type, childIndex }, clearStreamFunc, clearUrlFunc }: any) => {
        if (!!stream) {
            uploadoFile({ stream, title: form?.[currentId]?.label, callback: func, fieldType: type, currentId, childIndex, clearStreamFunc, clearUrlFunc });
        }
    }

    useEffect(() => {
        if (form?.[currentId]?.type === "file") {
            manageFile({
                index: String(form?.[currentId]?.field),
                uploadArgs: { func: handleSetFilledForm },
                clearStreamFunc: setStream,
                clearUrlFunc: setFileUrl
            })
        }
        if (form?.[currentId]?.type === "text_file") {
            manageFile({
                index: String(form?.[currentId].children[0]?.field),
                uploadArgs: { func: specialHandleSetFilledForm, type: form?.[currentId]?.type, childIndex: 0 },
                clearStreamFunc: setStream,
                clearUrlFunc: setFileUrl
            })
        }
    }, [currentId, stream]);

    const getFile = () => {
        if (["file", "text_file"].includes(form?.[currentId]?.type) && !stream && !fileUrl) {
            let index;
            if (form?.[currentId]?.type === "file") {
                index = String(form?.[currentId]?.field);

            }
            if (form?.[currentId]?.type === "text_file") {
                index = String(form?.[currentId].children[0]?.field);
            }
            const fileUuid = filled_form?.[String(index)]
            if (fileUuid) {
                try {
                    axiosClient?.get(`/respostas/file/${fileUuid}`).then((data) => {
                        if (!data) return;
                        const { data: { data: { label, type, path } } } = data;
                        if (label && type && path) {
                            const fileObject = new File([], `${String(path).replace('response/', '')}`);
                            setStream(fileObject)
                            setFileUrl(`${backend}/${path}`)
                        }
                    })
                } catch (e) {
                }
            }
        }
    }

    const debounceGetFile = useDebounce(getFile, 1000);

    useEffect(() => {
        debounceGetFile();
    }, [form, filled_form]);

    useEffect(() => {
        const listMasks = form?.[currentId]?.classes
        if (listMasks) {
            JSON?.parse(listMasks)?.map((raw) => {
                if (raw.trim().toLowerCase() == "cpf") {
                    setMask('999.999.999-99');
                }

                if (raw.trim().toLowerCase() == "cnpj") {
                    setMask('99.999.999/0001-99');
                }

                if (raw.trim().toLowerCase() == "cep") {
                    setMask('99999-999');
                }

                if (raw.trim().toLowerCase() == "cnae") {
                    setMask('9999-9/99');
                }

                if (raw.trim().toLowerCase() == "cnae") {
                    setMask('9999-9/99');
                }

                if (raw.trim().toLowerCase() == "telefone fixo") {
                    setMask('(99) 9999-9999');
                }

                if (raw.trim().toLowerCase() == "telefone celular") {
                    setMask('(99) 9 9999-9999');
                }

                if (raw.trim().toLowerCase() == "data") {
                    setMask('99/99/9999');
                }
            })
        }
    }, [currentId, form]);
    return (
        <div className={'w-full'}>
            {
                form?.[currentId]?.type === "select" ?
                    <SelectResponse form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handleSetFilledForm} />
                    : form?.[currentId]?.type === "text" ?
                        <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handleSetFilledForm} />
                        : form?.[currentId]?.type === "checkbox" ?
                            <CheckBoxResponse form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handleSetFilledForm} />
                            : form?.[currentId]?.type === "checkbox_na" ?
                                <CheckBoxNAResponse form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handleSetFilledForm} />
                                : form?.[currentId]?.type === "text_file" ?
                                    <TextFileResponse stream={stream} setStream={setStream} mask={mask} form={form} filled_form={filled_form} currentId={currentId} handle={handleSetFilledForm} url={fileUrl} />
                                    : form?.[currentId]?.type === "three_text" ?
                                        <TripleTextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handle={handleSetFilledForm} />
                                        : form?.[currentId]?.type === "file" ?
                                            <FileInputComponent id={form?.[currentId]?.field || idForFileInput} stream={stream} opt={"JPG, JPEG, PNG (MAX. 20MB)"} accept={'.jpg, .jpeg, .png'} setStream={setStream} title={form?.[currentId]?.label} url={fileUrl} />
                                            : form?.[currentId]?.type === "select_double_text" ?
                                                <SelectDoubleText form={form} filled_form={filled_form} currentId={currentId} handle={handleSetFilledForm} mask={mask} />
                                                : form?.[currentId]?.type === "presente_ausente_na_double_text" ?
                                                    <PresenteAusenteNA form={form} filled_form={filled_form} currentId={currentId} handle={handleSetFilledForm} mask={mask} />
                                                    : null
            }
        </div>
    );
};

export default DynamicFullFormComponent;
