import React, { useState } from 'react';
import FileInputComponent from "../../Forms/v2/FileInput";
import TextResponse from "./Text";
import uuid from 'react-uuid';

const TextFileResponse = ({ stream, setStream, mask, form, filled_form, currentId, handle, url }: any) => {
    const [id, setId] = useState(form[currentId]?.children[0]?.field || String(uuid()));
    return (
        <div className={'flex flex-col gap-2 border border-gray-400 rounded-lg p-4'}>
            {
                !!form[currentId]?.children[0]?.visibility ?
                    <FileInputComponent id={id} stream={stream} opt={"JPG, JPEG, PNG (MAX. 3MB)"} accept={'.jpg, .jpeg, .png'} setStream={setStream} title={form[currentId]?.children[0]?.label} url={url} />
                    : ''
            }

            {
                !!form[currentId]?.children[1]?.visibility ?
                    <TextResponse mask={mask} form={form} filled_form={filled_form} currentId={currentId} handleSetFilledForm={handle} />
                    : ''
            }
        </div>
    );
};

export default TextFileResponse;
